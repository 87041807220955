import { Box, Skeleton } from "@mui/material";
import { useMemo } from "react";

export const CenteredPicture = ({ src, alt, customHeight }) => {
  const parsedHeight = useMemo(() => {
    return customHeight ?? "16rem";
  }, [customHeight]);
  return (
    <Box
      sx={{
        width: { xs: "100%", sm: "50%" },
        height: parsedHeight,
        display: "flex",
        justifyContent: "center",
        margin: "0 auto 2rem auto",
      }}
    >
      {src && alt ? (
        <img
          loading="lazy"
          alt={alt}
          src={src}
          style={{
            width: "100%",
            height: parsedHeight,
            objectFit: "contain",
          }}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "16rem",
          }}
        />
      )}
    </Box>
  );
};
