import { useState } from "react";
import { Link } from "react-router";

export const CustomLink = ({
  linkHref,
  linkText,
  primaryColor,
  secondaryColor,
  isActive,
  clickAction,
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Link
      to={linkHref}
      style={{
        display: "block",
        color: hovered || isActive ? secondaryColor : primaryColor,
        backgroundColor: hovered || isActive ? primaryColor : secondaryColor,
        border: `2px solid ${
          hovered || isActive ? secondaryColor : primaryColor
        }`,
        textDecoration: "none",
        textAlign: "center",
        padding: "0.75rem 0",
        minWidth: "10rem",
        borderRadius: "4px",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        clickAction && clickAction();
      }}
    >
      {linkText}
    </Link>
  );
};
