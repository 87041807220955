import { useParams } from "react-router";
import { CustomCarousel } from "../generic/CustomCarousel";
import { Box } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { CustomButton } from "../generic/CustomButton";
import { useImagesOrSkeleton } from "../../hooks/useImagesOrSkeleton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton } from "../generic/IconButton";
import picturesInfo from "../../utils/images/picturesInfo.json";

export const YearHistory = () => {
  const { year } = useParams();

  const [isAllPicturesOpened, setIsAllPicturesOpened] = useState(false);
  const [isTeamsPicturesOpened, setIsTeamsPicturesOpened] = useState(false);
  const [pagination, setPagination] = useState(0);
  // const [startKey, setStartKey] = useState(0);

  const yearInfo = useMemo(() => {
    return picturesInfo.find((singleYear) => singleYear.year === Number(year));
  }, [year]);
  const islastPage = useMemo(() => {
    return pagination * 20 + 20 >= yearInfo.picturesCount;
  }, [pagination, yearInfo.picturesCount]);
  const teamsPicturesComponents = useMemo(() => {
    return yearInfo.teamsFiles
      ? yearInfo.teamsFiles.map((fileName, index) => (
          <a
            href={`/assets/history/large/tournoi_${year}/teams/${fileName}`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`/assets/history/mini/tournoi_${year}/teams/${fileName}`}
              alt={fileName}
              key={index}
            />
          </a>
        ))
      : undefined;
  }, [year, yearInfo.teamsFiles]);

  const imagesComponents = useMemo(() => {
    const pictures = [];
    const paginationStart = pagination ? pagination * 20 : 1;
    for (let i = 0; i < 21; i++) {
      const pictureNumber = paginationStart + i;
      if (pictureNumber <= yearInfo.picturesCount) {
        pictures.push(
          <a
            href={`/assets/history/large/tournoi_${year}/${year}_${pictureNumber}.jpg`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`/assets/history/mini/tournoi_${year}/${year}_${pictureNumber}.jpg`}
              alt={`${year}-${pictureNumber}`}
              key={pictureNumber}
            />
          </a>
        );
      }
    }
    return pictures;
  }, [pagination, year, yearInfo.picturesCount]);

  // const orderImages = useCallback(() => {
  //   if (startKey === 0) {
  //     return imagesComponents;
  //   }
  //   const orderedPictures = [];
  //   for (let i = startKey; i < imagesComponents.length; i++) {
  //     orderedPictures.push(imagesComponents[i]);
  //   }
  //   for (let i = 0; i < startKey; i++) {
  //     orderedPictures.push(imagesComponents[i]);
  //   }
  //   return orderedPictures;
  // }, [imagesComponents, startKey]);
  // const handleCarrouselNav = useCallback(
  //   (key, direction) => {
  //     const isNextPage = key === imagesComponents.length - 1 && direction === 1;
  //     const isPrevPage = key === 0 && direction === -1;
  //     if (isNextPage || isPrevPage) {
  //       setPagination((prev) => prev + direction);
  //       setStartKey(0);
  //     } else if (typeof Number(key) === "number") {
  //       setStartKey(Number(key));
  //     }
  //   },
  //   [imagesComponents]
  // );

  useEffect(() => {
    setPagination(0);
  }, [year]);

  return (
    <>
      <section>
        <h2>{year}</h2>
        <CustomCarousel
          carouselElements={useImagesOrSkeleton({
            imagesComponents,
          })}
          // hasArrows
          // arrowCallback={handleCarrouselNav}
          noShuffle
        />
        {year === "2024" && (
          <p>
            Merci à Philippe Bertrand, Sandrine Combe et{" "}
            <a
              href="https://www.instagram.com/picsbyddrey/?igshid=MjEwN2IyYWYwYw%3D"
              title="la page instagram de Audrey Rindone"
              target="_blank"
              rel="noreferrer"
            >
              la photographe professionnelle Audrey Rindone
            </a>{" "}
            pour leurs photos.
          </p>
        )}
      </section>
      <section
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "flex-start",
          alignItems: "center",
          margin: "1rem 0",
          gap: "1rem",
        }}
      >
        {teamsPicturesComponents && (
          <>
            <CustomButton
              buttonText={
                isTeamsPicturesOpened
                  ? "Cacher les équipes"
                  : "Voir les équipes"
              }
              clickAction={() =>
                setIsTeamsPicturesOpened(!isTeamsPicturesOpened)
              }
            />
            {isTeamsPicturesOpened && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexFlow: "row wrap",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "0.5rem",
                  "& img": {
                    xs: {
                      width: "10rem",
                      maxWidth: "30vw",
                      height: "10rem",
                      maxHeight: "30vw",
                      objectFit: "contain",
                    },
                    sm: {
                      width: "10rem",
                      maxWidth: "17vw",
                      height: "10rem",
                      maxHeight: "17vw",
                      objectFit: "contain",
                    },
                  },
                }}
              >
                {teamsPicturesComponents}
              </Box>
            )}
          </>
        )}
        <CustomButton
          buttonText={
            isAllPicturesOpened ? "Cacher les photos" : "Voir toutes les photos"
          }
          clickAction={() => setIsAllPicturesOpened(!isAllPicturesOpened)}
        />
        {isAllPicturesOpened && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              gap: "0.5rem",
              "& img": {
                xs: {
                  width: "10rem",
                  maxWidth: "30vw",
                  height: "10rem",
                  maxHeight: "30vw",
                  objectFit: "contain",
                },
                sm: {
                  width: "10rem",
                  maxWidth: "17vw",
                  height: "10rem",
                  maxHeight: "17vw",
                  objectFit: "contain",
                },
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexFlow: "row nowrap",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                buttonIcon={ArrowBackIosNewIcon}
                clickAction={() => setPagination((prev) => prev - 1)}
                isDisabled={pagination === 0}
              />
              <IconButton
                buttonIcon={ArrowForwardIosIcon}
                clickAction={() => setPagination((prev) => prev + 1)}
                isDisabled={islastPage}
              />
            </Box>
            {/* {imagesComponents.map((image) => {
              return (
                <a href={image.props.src} target="_blank" rel="noreferrer">
                  {image}
                </a>
              );
            })} */}
            {imagesComponents}
          </Box>
        )}
      </section>
      {/* TODO: Ajouter les résultats plus tard*/}
    </>
  );
};
