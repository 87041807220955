import { Box } from "@mui/material";
import { shuffle } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import { CarouselButton } from "../specific/CarouselButton";

export const CustomCarousel = ({
  carouselElements,
  customHeight,
  hasArrows,
  arrowCallback,
  noShuffle,
}) => {
  const parsedHeight = useMemo(() => {
    return customHeight ?? "20rem";
  }, [customHeight]);

  const randomId = useMemo(() => {
    return `customCarousel-${Math.random()}`;
  }, []);

  const animateElements = useCallback(
    (duration, iterations) => {
      const carouselContainers = document.getElementById(randomId).children;
      const baseAnimationValues = [];
      for (let i = 0; i < carouselContainers.length; i++) {
        baseAnimationValues.push(-100 * i);
      }
      for (let i = 0; i < carouselContainers.length; i++) {
        const animationSteps = [];
        for (let j = 0; j < carouselContainers.length; j++) {
          const firstValue = j === 0 ? 0 : j / carouselContainers.length;
          const addedValue = (1 / carouselContainers.length) * (2 / 3);
          animationSteps.push({
            left: baseAnimationValues[j] + i * 100 + "%",
            offset: firstValue,
          });
          animationSteps.push({
            left: baseAnimationValues[j] + i * 100 + "%",
            offset: firstValue + addedValue,
          });
        }
        animationSteps.push({
          left:
            baseAnimationValues[baseAnimationValues.length - 1] + i * 100 + "%",
        });
        carouselContainers[i].animate(animationSteps, {
          duration: duration * carouselContainers.length,
          iterations: iterations,
        });
      }
    },
    [randomId]
  );

  const isElementInViewport = (el) => {
    const elementLimits = el.getBoundingClientRect();

    return (
      elementLimits.top >= 0 &&
      elementLimits.left >= 0 &&
      elementLimits.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      elementLimits.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const getDisplayedElementKey = () => {
    const carouselContainers = document.getElementById(randomId).children;
    for (let i = 0; i < carouselContainers.length; i++) {
      if (isElementInViewport(carouselContainers[i])) {
        return carouselContainers[i].getAttribute("data-key");
      }
    }
  };

  const handleArrowClick = (direction) => {
    if (!hasArrows || !arrowCallback) {
      return undefined;
    }
    arrowCallback(getDisplayedElementKey(), direction);
  };

  const parsedElements = useMemo(() => {
    if (noShuffle) {
      return carouselElements;
    }
    return shuffle(carouselElements);
  }, [carouselElements, noShuffle]);

  useEffect(() => {
    animateElements(8000, 100);
  }, [animateElements]);

  return (
    <div
      style={{
        width: "100%",
        height: parsedHeight,
        marginBottom: customHeight ? "0" : "1rem",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {hasArrows && (
        <>
          <CarouselButton
            direction={-1}
            clickAction={() => handleArrowClick(-1)}
          />
          <CarouselButton
            direction={1}
            clickAction={() => handleArrowClick(1)}
          />
        </>
      )}
      <div
        id={randomId}
        style={{
          width: "100%",
          height: parsedHeight,
          marginBottom: customHeight ? "0" : "1rem",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {parsedElements.map((singleElement, index) => {
          return (
            <Box
              sx={{
                width: "100%",
                height: parsedHeight,
                position: "absolute",
                overflow: "hidden",
                left: 100 * index + "%",
                "& *": {
                  width: "100%",
                  height: parsedHeight,
                  objectFit: "contain",
                },
              }}
              key={index}
              data-key={index}
            >
              {singleElement}
            </Box>
          );
        })}
      </div>
    </div>
  );
};
