import { useLocation } from "react-router";
import { Sponsors } from "../generic/Sponsors";
import { useMemo } from "react";

export const CustomHeader = () => {
  const { pathname } = useLocation();

  const seededRandom = useMemo(() => {
    return Math.random() * (1000 - pathname.length) + pathname.length;
  }, [pathname]);

  const halfRandom = useMemo(() => {
    return 500 - pathname.length / 2;
  }, [pathname]);
  return (
    <header>
      <img
        loading="lazy"
        alt={
          seededRandom < halfRandom
            ? "tournoi à nous de jouer"
            : "association pour guillaume"
        }
        src={
          seededRandom < halfRandom
            ? require("../../assets/generic/images/logo_tournoi.jpg")
            : require("../../assets/generic/images/logo-nobg.png")
        }
        style={{
          maxWidth: window.screen.width < 600 ? "50%" : "100%",
        }}
      />
      {window.screen.width < 600 && <Sponsors />}
    </header>
  );
};
