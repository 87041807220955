import { PlanningItem } from "../components/specific/PlanningItem";
import { CustomCarousel } from "../components/generic/CustomCarousel";
import { useMemo } from "react";
import { FloatedPicture } from "../components/generic/FloatedPicture";

const planning = [
  {
    time: "9h",
    description:
      "Accueil des équipes. Présentation à la table de marque des capitaines pour les équipes adultes ainsi que des parents pour les enfants pour finir de valider les inscriptions.",
    src: "/assets/history/mini/tournoi_2024/2024_17.jpg",
    alt: "accueil des équipes",
  },
  {
    time: "9h45",
    description:
      "Topo arbitrage et explications sur le but et le déroulement de la journée.",
    src: "/assets/history/mini/tournoi_2024/2024_15.jpg",
    alt: "explication de la journée",
  },
  {
    time: "10h",
    description:
      "Début des matchs de poule et des matchs des enfants sur le stade. En fonction du nombre d'équipe, 3, 4 ou 5 poules de 5, 6 ou 7 équipes.",
    src: "/assets/history/mini/tournoi_2024/2024_188.jpg",
    alt: "début des matchs",
  },
  {
    time: "12h",
    description:
      "Pause déjeuner avec à votre disposition une buvette qui vous propose : hot-dog, sandwichs froids, boissons, crêpes, glaces.",
    src: "/assets/history/mini/tournoi_2024/2024_34.jpg",
    alt: "pause déjeuner",
  },
  {
    time: "13h30",
    description: "Reprise des matchs de poule sur le stade.",
    src: "/assets/history/mini/tournoi_2024/2024_202.jpg",
    alt: "reprise des matchs",
  },
  {
    time: "15h",
    description: "Quarts de finale adultes, toujours sur le stade de foot.",
    src: "/assets/history/mini/tournoi_2024/2024_429.jpg",
    alt: "quarts de finale adulte",
  },
  {
    time: "15h45, 16h30 & 17h15",
    description: "Finales des enfants dans le gymnase.",
    src: "/assets/history/mini/tournoi_2024/2024_652.jpg",
    alt: "finales enfants",
  },
  {
    time: "16h05 & 16h55",
    description: "Demi finales adultes dans le gymnase.",
    src: "/assets/history/mini/tournoi_2024/2024_669.jpg",
    alt: "demi-finales adultes",
  },
  {
    time: "17h35",
    description: "Finale des adultes dans le gymnase.",
    src: "/assets/history/mini/tournoi_2023/2023_774.jpg",
    alt: "finale adultes",
  },
  {
    time: "18h",
    description:
      "Remise symbolique du chèque des bénéfices de l'édition précédante au(x) représentant(s) de l'Institut Paoli-Calmettes à Marseille.",
    src: "/assets/history/mini/tournoi_2024/2024_112.jpg",
    alt: "remise du chèque",
  },
  { time: "18h15", description: "Remise des récompenses.", noPicture: true },
  { time: "18h30", description: "Apéritif offert.", noPicture: true },
];

export const Tournament = () => {
  const imagesComponents = useMemo(() => {
    const pictures = [];
    for (let i = 200; i < 221; i++) {
      pictures.push(
        <img
          src={`/assets/history/mini/tournoi_2024/2024_${i}.jpg`}
          alt={`2024-${i}`}
        />
      );
    }
    return pictures;
  }, []);

  return (
    <main>
      <h1>Tournoi</h1>
      <section>
        <CustomCarousel carouselElements={imagesComponents} />
        <p>
          Convivialité et amitié, deux valeurs maîtresses pour Guillaume, sont
          les bases du tournoi. Chaque année, le stade et le gymnase de Saint
          Bonnet accueillent une trentaine d'équipes "adultes" et une dizaine
          d'équipes "enfants", composées de 5 à 8 joueurs, obligatoirement
          mixtes et avec au plus 2 licencié(e)s. Ces équipes s'affrontent, par
          poules, sur le stade de foot. les 1/2 finales et finales se déroulent
          ensuite dans le gymnase.
        </p>
        <FloatedPicture
          side={"left"}
          src={"/assets/history/mini/tournoi_2024/2024_645.jpg"}
          alt={"la convivialité du tournoi"}
        />
        <p>
          Une urne pour récolter des dons, une tombola, une buvette, ainsi que
          la vente de casquettes et de tee-shirts, agrémentent cette journée et
          permettent ainsi de récolter des fonds destinés à l'institut
          Paoli-Calmettes de Marseille.
        </p>
        <p>
          Le slogan "pour Guillaume à nous de jouer" symbolise à lui seul tout
          l'esprit de cette manifestation qui réunit chaque année environ 400
          personnes. Le combat de Guillaume contre la maladie, son implication
          dans la vie du club, ses qualités de coeur et de rassemblement sont,
          ce jour-là, récompensés par ce magnifique élan de solidarité et de
          sympathie.
        </p>
      </section>
      <section>
        {planning.map((item, index) => {
          return (
            <PlanningItem
              key={index}
              time={item.time}
              description={item.description}
              src={item.src}
              alt={item.alt}
              pictureSide={index % 2}
              noPicture={item.noPicture}
            />
          );
        })}
        <PlanningItem
          time="Tout au long de la journée"
          description={
            "Une buvette, une boutique proposant des produits à l'effigie de l'association, une tombola, une pesée du jambon et des photos d'équipes."
          }
          noPicture
        />
      </section>
    </main>
  );
};
