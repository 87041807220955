import { useMemo } from "react";
import { shuffle } from "lodash";

const bigImages = require.context("../assets/sponsors/images/big", true);
const bigImagesList = bigImages.keys().map((image) => bigImages(image));

const smallImages = require.context("../assets/sponsors/images/small", true);
const smallImagesList = smallImages.keys().map((image) => smallImages(image));

export const Partners = () => {
  const bigSponsors = useMemo(() => {
    const imagesArray = shuffle(
      bigImagesList.map((image, index) => {
        return (
          <img
            key={index}
            src={image}
            alt={`image-${index}`}
            style={{ objectFit: "contain", width: "12rem", height: "12rem" }}
          />
        );
      })
    );
    return imagesArray;
  }, []);

  const smallSponsors = useMemo(() => {
    const imagesArray = shuffle(
      smallImagesList.map((image, index) => {
        return (
          <img
            key={index}
            src={image}
            alt={`image-${index}`}
            style={{ objectFit: "contain", width: "8rem", height: "8rem" }}
          />
        );
      })
    );
    return imagesArray;
  }, []);

  return (
    <main>
      <h1>Merci à nos partenaires pour leur générosité</h1>
      <section
        style={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "0.5rem",
        }}
      >
        {bigSponsors}
      </section>
      <section
        style={{
          display: "flex",
          flexFlow: "row wrap",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "0.5rem",
        }}
      >
        {smallSponsors}
      </section>
    </main>
  );
};
