import { BrowserRouter, Route, Routes } from "react-router";
import "./App.css";
import { CustomFooter } from "./components/global/CustomFooter";
import { CustomHeader } from "./components/global/CustomHeader";
import { NavMenu } from "./components/global/NavMenu";
import { Home } from "./pages/Home";
import { Association } from "./pages/Association";
import { Tournament } from "./pages/Tournament";
import { Partners } from "./pages/Partners";
import { Press } from "./pages/Press";
import { History } from "./pages/History";
import Registration from "./pages/Registration";

function App() {
  return (
    <BrowserRouter>
      <CustomHeader />
      <NavMenu />
      <Routes>
        <Route index element={<Home />} />
        <Route path="association" element={<Association />} />
        <Route path="tournoi" element={<Tournament />} />
        <Route path="partenaires" element={<Partners />} />
        <Route path="presse" element={<Press />} />
        <Route path="historique" element={<History />} />
        <Route path="inscription" element={<Registration />} />
        <Route path="historique/:year" element={<History />} />
      </Routes>
      <CustomFooter />
    </BrowserRouter>
  );
}

export default App;
