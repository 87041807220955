import pressData from "../assets/press/index.json";
import { useMemo } from "react";
import { PressYear } from "../components/specific/PressYear";

export const Press = () => {
  const groupedPapers = useMemo(() => {
    const now = new Date();
    const output = {};
    for (let i = now.getFullYear(); i > 2001; i--) {
      const papers = pressData.filter((pressElement) =>
        pressElement.date.includes(i.toString())
      );
      if (papers.length) {
        output[i] = papers;
      }
    }
    return output;
  }, []);
  return (
    <main>
      <h1>Du côté de la presse</h1>
      <p style={{ marginBottom: "2rem" }}>
        La presse parle de nos actions chaque année pour relayer le grand succès
        du tournoi.
      </p>
      {Object.entries(groupedPapers).map(([key, value]) => {
        return <PressYear year={key} yearData={value} key={key} />;
      })}
    </main>
  );
};
