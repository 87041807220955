import { Button } from "@mui/material";
import { useState } from "react";
import { styleConst } from "../../utils/style";

export const IconButton = ({
  buttonIcon,
  primaryColor,
  secondaryColor,
  clickAction,
  isDisabled,
}) => {
  const [hovered, setHovered] = useState(false);
  const primary = primaryColor || styleConst.primaryColor;
  const secondary = secondaryColor || styleConst.pgbackground;

  const Icon = buttonIcon;

  return (
    <Button
      sx={{
        display: "block",
        color: hovered ? primary : secondary,
        backgroundColor: hovered ? secondary : primary,
        border: `2px solid ${hovered ? primary : secondary}`,
        textDecoration: "none",
        textAlign: "center",
        padding: "0.75rem 0.5rem",
        fontFamily: "inherit",
        width: "3rem",
        height: "3rem",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        clickAction && clickAction();
      }}
      disabled={isDisabled}
    >
      <Icon />
    </Button>
  );
};
