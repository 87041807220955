import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { PressItem } from "./PressItem";

export const PressYear = ({ year, yearData }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <p>{year}</p>
      </AccordionSummary>
      <AccordionDetails>
        {yearData.map((singlePaper) => {
          return (
            <PressItem
              date={singlePaper.date}
              paper={singlePaper.paper}
              href={singlePaper.href}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
