import { CenteredPicture } from "../components/generic/CenteredPicture";

export const Home = () => {
  return (
    <main>
      <h1>Accueil</h1>
      <p>
        Bienvenue sur le site de l'association "Pour Guillaume". Vous pourrez
        ici retrouver toutes les informations sur l'association ainsi que sur
        son tournoi de handball annuel au profit de la lutte contre le cancer.
      </p>
      <h2>Prochain évènement</h2>
      <p>La prochaine édition du tournoi aura lieu le dimanche 8 juin 2025.</p>
      <CenteredPicture
        src={"/assets/divers/images/flyer.jpg"}
        alt={"affiche de la prochaine édition"}
        customHeight={"40rem"}
      />
    </main>
  );
};
