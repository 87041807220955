import { Button } from "@mui/material";
import { useState } from "react";
import { styleConst } from "../../utils/style";

export const CustomButton = ({
  buttonText,
  primaryColor,
  secondaryColor,
  clickAction,
  buttonType,
  startIcon,
  disabled,
}) => {
  const [hovered, setHovered] = useState(false);
  const primary = primaryColor || styleConst.primaryColor;
  const secondary = secondaryColor || styleConst.pgbackground;

  return (
    <Button
      sx={{
        display: "flex",
        color: hovered ? primary : secondary,
        backgroundColor: hovered ? secondary : primary,
        border: `2px solid ${hovered ? primary : secondary}`,
        textDecoration: "none",
        textAlign: "center",
        padding: "0.75rem 0.5rem",
        width: "fit-content",
        minWidth: "10rem",
        fontFamily: "inherit",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        clickAction && clickAction();
      }}
      type={buttonType ? buttonType : "button"}
      startIcon={startIcon}
      color={hovered ? primary : secondary}
      disabled={disabled}
    >
      {buttonText}
    </Button>
  );
};
