import { Box } from "@mui/material";
import { CenteredPicture } from "../components/generic/CenteredPicture";
import { FloatedPicture } from "../components/generic/FloatedPicture";
import { AssoMember } from "../components/specific/AssoMember";

const dons = [
  { label: "Tournoi 2003", money: "1 937.79" },
  { label: "Tournoi 2004", money: "2 434.68" },
  { label: "Tournoi 2005", money: "2 707.28" },
  { label: "Tournoi 2006", money: "3 039.40" },
  { label: "Tournoi 2007", money: "3 441.64" },
  { label: "Tournoi 2008", money: "3 633.34" },
  { label: "Tournoi 2009", money: "3 815.93" },
  { label: "Tournoi 2010", money: "4 205.49" },
  { label: "Buvette Rallye neige et glace 2011", money: "575.15" },
  { label: "Tournoi 2011", money: "5 113.58" },
  { label: "Buvette Rallye neige et glace 2012", money: "1 295.73" },
  { label: "Tournoi 2012", money: "6 152.24" },
  { label: "Buvette Rallye neige et glace 2013", money: "1 068.74" },
  { label: "Tournoi 2013", money: "5 544.79" },
  { label: "Tournoi 2014", money: "5 884.36" },
  { label: "Buvette Rallye Hautes-Alpes 2014", money: "195.55" },
  { label: "Tournoi 2015", money: "6 082,15" },
  { label: "Tournoi 2016", money: "6 527.99" },
  { label: "Tournoi 2017", money: "6 106.25" },
  { label: "Tournoi 2018", money: "6 727.84" },
  { label: "Collecte 2019", money: "4 008.00" },
  { label: "Collecte 2022", money: "770" },
  { label: "Escapade champsaurine 2022", money: "294" },
  { label: "Tournoi 2022", money: "8.796,07" },
  { label: "Escapade champsaurine 2023", money: "340" },
  { label: "Tournoi 2023", money: "8.850,17" },
  { label: "Escapade champsaurine 2024", money: "350" },
  { label: "Tournoi 2024", money: "9.028,34" },
];

const members = [
  {
    src: "/assets/members/aude.jpg",
    alt: "Aude Vincent",
    role: "Présidente",
  },
  {
    src: "/assets/members/tristan.jpg",
    alt: "Tristan Smith",
    role: "Vice-président",
  },
  {
    src: "/assets/members/aurelie.jpg",
    alt: "Aurélie Raynaud",
    role: "Trésorière",
  },
  {
    src: "/assets/members/sarah.jpg",
    alt: "Sarah Smith",
    role: "Secrétaire",
  },
  {
    src: "/assets/members/isabelle.jpg",
    alt: "Isabelle Pellegrin",
    role: "Secrétaire",
  },
  {
    src: "/assets/members/audrey.jpg",
    alt: "Audrey Motte",
  },
  {
    src: "/assets/members/benjamin.jpg",
    alt: "Benjamin Gillet",
  },
  {
    src: "/assets/members/cath.jpg",
    alt: "Cathy Massa",
  },
  {
    src: "/assets/members/marjorie.jpg",
    alt: "Marjorie Millon",
  },
  {
    src: "/assets/members/sebastien.jpg",
    alt: "Sébastien Duperret",
  },
  {
    src: "/assets/members/laura.jpg",
    alt: "Laura Pascal",
  },
];
export const Association = () => {
  return (
    <main>
      <h1>Association</h1>
      <section>
        <Box
          sx={{
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "center",
            gap: "0.25rem",
          }}
        >
          <AssoMember
            alt={"Dédé, père de Guillaume"}
            src={"/assets/divers/images/dede.jpg"}
          />
          <AssoMember
            src={"/assets/divers/images/guillaume.jpg"}
            alt={"Guillaume Vincent"}
          />
          <AssoMember
            alt={"Jeff, ami de Guillaume"}
            src={"/assets/divers/images/jeff.jpg"}
          />
        </Box>
        <p>
          Depuis 2003, le tournoi « à nous de jouer !» est un événement
          incontournable dans la vallée du Champsaur. Plus qu’un simple tournoi
          de handball, il incarne le souvenir, l’amitié et la solidarité. Créé
          en hommage à Guillaume, vice-président du HBCCV, il perpétue également
          la mémoire de Jeff, Dédé et de tous ceux qui nous ont quittés, mais
          dont l’esprit continue de nous rassembler.
        </p>
        <p>
          Organisé par l’association Pour Guillaume, en partenariat avec le
          HBCCV, ce tournoi réunit chaque année près de 400 participants,
          licenciés et non-licenciés, dans un esprit de fair-play et de
          convivialité. Les équipes mixtes de 5 à 8 joueurs s’affrontent lors de
          matchs sur le stade, avant les demi-finales et la finale en gymnase.
        </p>
        <p>
          Au-delà du sport, cet événement mobilise toute la vallée pour une
          cause solidaire. Grâce aux dons, à une tombola, à une buvette et à des
          ventes solidaires, les fonds récoltés sont reversés à l’Institut
          Paoli-Calmettes pour soutenir la lutte contre la maladie.
        </p>
        <p>
          Ce tournoi est un moment de partage unique, où se mêlent souvenir,
          fraternité et engagement. "Pour Guillaume, Jeff, Dédé et tous les
          autres, à nous de jouer !"
        </p>
        {/* <p>
          Vous pouvez la soutenir en devenant adhérent. Pour cela il vous suffit
          de remplir le coupon et de reverser une cotisation annuelle minimum de
          5€.
        </p>
        <a href="/assets/divers/2024-bulletin-adhesion.pdf" download>
          Coupon d'adhésion annuelle pour l'association "Pour Guillaume"
        </a> */}
      </section>
      <section>
        <h2>À quoi vos dons servent-ils ?</h2>
        <CenteredPicture
          src={"/assets/divers/images/logo-ipc2025.jpg"}
          alt={"Institut Paoli-Calmettes"}
        />
        <p>
          Les dons à l'association, qu'ils soient fait par le biais de
          l'inscription du tournoi ou non, sont reversés à{" "}
          <a
            href="http://www.institutpaolicalmettes.fr/"
            target="_blank"
            rel="noreferrer"
          >
            l'institut Paoli-Calmettes de Marseille
          </a>{" "}
          pour aider la lutte contre le cancer.
        </p>
        <p>
          Depuis 2003, ce sont <span>108 926,50 €</span> qui ont été reversé
          pour lutter contre le cancer. En détails :
        </p>
        <FloatedPicture
          side={"left"}
          src={"/assets/divers/images/don.jpg"}
          alt={"remise du chèque à l'institut Paoli-Calmettes"}
        />
        <article
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "space-between",
          }}
        >
          {dons.map((don) => {
            return (
              <p>
                {don.label} : <span>{don.money} €</span>
              </p>
            );
          })}
        </article>
      </section>
      <section>
        <h2>Le bureau de l'association</h2>
        <article
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          {members.map((member) => (
            <AssoMember
              alt={member.alt}
              src={member.src}
              memberRole={member.role}
            />
          ))}
        </article>
        <article
          style={{
            display: "flex",
            flexFlow: "row wrap",
            justifyContent: "center",
            alignItems: "flex-start",
            gap: "1rem",
          }}
        >
          <h2>Membres d'honneur</h2>
          <AssoMember
            alt={"Rosy Vincent, mère de Guillaume"}
            src={"/assets/history/large/tournoi_2023/2023_40.jpg"}
            objectFit="none"
            objectPosition="57% 25%"
          />
          <AssoMember alt={"Simone Millon"} />
        </article>
      </section>
    </main>
  );
};
