import { Box } from "@mui/material";
import { CustomCarousel } from "./CustomCarousel";
import { useMemo } from "react";

const bigImages = require.context("../../assets/sponsors/images/big", true);
const bigImagesList = bigImages.keys().map((image) => bigImages(image));

const smallImages = require.context("../../assets/sponsors/images/small", true);
const smallImagesList = smallImages.keys().map((image) => smallImages(image));

export const Sponsors = () => {
  const bigSponsors = useMemo(() => {
    const imagesArray = bigImagesList.map((image, index) => {
      return <img key={index} src={image} alt={`image-${index}`} />;
    });
    return imagesArray;
  }, []);

  const smallSponsors = useMemo(() => {
    const imagesArray = smallImagesList.map((image, index) => {
      return <img key={index} src={image} alt={`image-${index}`} />;
    });
    return imagesArray;
  }, []);

  return (
    <Box
      sx={{
        height: { xs: "7.5rem", sm: "15.2rem" },
        width: { xs: "15.2rem", sm: "7.5rem" },
        display: "flex",
        flexFlow: { xs: "row nowrap", sm: "column nowrap" },
        gap: "0.2rem",
      }}
    >
      <Box sx={{ height: "7.5rem", width: "7.5rem" }}>
        <CustomCarousel
          customHeight={"7.5rem"}
          carouselElements={bigSponsors}
        />
      </Box>
      <Box
        sx={{
          height: "7.5rem",
          width: "7.5rem",
          display: "flex",
          flexFlow: "row wrap",
          gap: "0.1rem",
        }}
      >
        <Box sx={{ height: "3.7rem", width: "3.7rem" }}>
          <CustomCarousel
            customHeight={"3.7rem"}
            carouselElements={smallSponsors}
          />
        </Box>
        <Box sx={{ height: "3.7rem", width: "3.7rem" }}>
          <CustomCarousel
            customHeight={"3.7rem"}
            carouselElements={smallSponsors}
          />
        </Box>
        <Box sx={{ height: "3.7rem", width: "3.7rem" }}>
          <CustomCarousel
            customHeight={"3.7rem"}
            carouselElements={smallSponsors}
          />
        </Box>
        <Box sx={{ height: "3.7rem", width: "3.7rem" }}>
          <CustomCarousel
            customHeight={"3.7rem"}
            carouselElements={smallSponsors}
          />
        </Box>
      </Box>
    </Box>
  );
};
