import React, { useEffect, useMemo, useState } from "react";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SendIcon from "@mui/icons-material/Send";
import emailjs from "emailjs-com";
import { LabelInput } from "../components/Inputs";
import PlayerForm from "../components/PlayerForm";
import { format } from "date-fns";
import AReg from "../../src/assets/pdf/adult-registration.pdf";
import KReg from "../../src/assets/pdf/kid-registration.pdf";
import OReg from "../../src/assets/pdf/parental-auth.pdf";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ExpandMore, PersonAdd, Warning } from "@mui/icons-material";
import { CustomButton } from "../components/generic/CustomButton";
import { styleConst } from "../utils/style";

const Registration = () => {
  function ContactForm() {
    const [formData, setFormData] = useState({
      name: "",
      captain: "",
      telephone: "",
      players: [
        {
          surname: "",
          firstname: "",
          birthName: "",
          birthDate: "",
          sex: "H",
          email: "",
          licensed: "Non",
          adress: "",
        },
        {
          surname: "",
          firstname: "",
          birthName: "",
          birthDate: "",
          sex: "H",
          email: "",
          licensed: "Non",
          adress: "",
        },
        {
          surname: "",
          firstname: "",
          birthName: "",
          birthDate: "",
          sex: "H",
          email: "",
          licensed: "Non",
          adress: "",
        },
        {
          surname: "",
          firstname: "",
          birthName: "",
          birthDate: "",
          sex: "H",
          email: "",
          licensed: "Non",
          adress: "",
        },
        {
          surname: "",
          firstname: "",
          birthName: "",
          birthDate: "",
          sex: "H",
          email: "",
          licensed: "Non",
          adress: "",
        },
      ],
    });

    //Date
    const tournamentDate = useMemo(() => {
      return new Date(2025, 5, 8);
    }, []);
    const registrationOpenDate = useMemo(() => {
      const dateTemp = new Date(tournamentDate);
      dateTemp.setMonth(dateTemp.getMonth() - 1);
      return dateTemp;
    }, [tournamentDate]);
    const currentDate = new Date();

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [dialogMessage, setDialogMessage] = useState("");

    const canSubmit = useMemo(() => {
      const players = formData.players;

      const isValid =
        players.length >= 5 &&
        players.length <= 8 &&
        players.filter((player) => player.licensed === "Oui").length <= 2 &&
        players.every(
          (player) =>
            player.surname &&
            player.firstname &&
            player.birthDate &&
            new Date(player.birthDate) <=
              new Date(tournamentDate.getFullYear() - 14)
        );

      return isValid;
    }, [formData, tournamentDate]);

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const userId = process.env.REACT_APP_USER_ID;

    //WIP display error message ============
    const handleSubmit = (e) => {
      e.preventDefault();

      if (
        !formData.name ||
        !formData.captain ||
        !formData.telephone ||
        formData.players.lenght < 5
      ) {
        setErrorMessage("Tous les champs doivent être remplis.");
        return;
      }
      setErrorMessage("");
      const formattedPlayers = formData.players
        .map(
          (player, index) =>
            `<tr>
           <td>${index + 1}</td>
           <td>${player.surname}</td>
           <td>${player.firstname}</td>
           <td>${format(player.birthDate, "dd/MM/yyyy")}</td>
           <td>${player.birthName}</td>
           <td>${player.sex}</td>
           <td>${player.email}</td>
           <td>${player.licensed}</td>
           <td>${player.adress}</td>
         </tr>`
        )
        .join("");

      const templateParams = {
        name: formData.name,
        captain: formData.captain,
        telephone: formData.telephone,
        playersTable: `
          <table border="1" cellspacing="0" cellpadding="5">
            <tr>
              <th>#</th>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Nom de Naissance</th>
              <th>Date de naissance</th>
              <th>Sexe</th>
              <th>Email</th>
              <th>Licencié</th>
              <th>Adresse</th>
            </tr>
            ${formattedPlayers}
          </table>
        `,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => {
          console.log("SUCCESS!", response.status, response.text);
          setSuccessMessage(
            "Email envoyé avec succès ! Nous vous confirmerons l'inscription de votre équipe par mail dans les plus brefs délais."
          );
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
          // setFormData({ name: '', captain: '', telephone: '' });
        })
        .catch((error) => {
          console.log("FAILED...", error);
          setErrorMessage(
            "Erreur lors de l'envoi de l'email. Veuillez réessayer."
          );
        });
    };

    const handleChange = (e) => {
      const { name, value, dataset } = e.target;
      const index = dataset.index;

      if (index !== undefined) {
        const updatedPlayers = [...formData.players];
        updatedPlayers[index] = {
          ...updatedPlayers[index],
          [name]: value,
        };

        setFormData({
          ...formData,
          players: updatedPlayers,
        });
      } else {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    };

    const addPlayer = () => {
      setFormData((prevState) => ({
        ...prevState,
        players: [
          ...prevState.players,
          {
            surname: "",
            firstname: "",
            birthDate: "",
            birthName: "",
            sex: "",
            email: "",
            licensed: "",
            adress: "",
          },
        ],
      }));
    };

    const removePlayer = () => {
      setFormData((prevState) => ({
        ...prevState,
        players: prevState.players.slice(0, -1),
      }));
    };

    const onDlBtnClick = (file, name) => {
      const link = document.createElement("a");
      link.href = file;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    useEffect(() => {
      if (successMessage) {
        setDialogMessage(successMessage);
      }
      if (errorMessage) {
        setDialogMessage(errorMessage);
      }
    }, [successMessage, errorMessage]);

    return (
      <main>
        <h1>Inscription</h1>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Dialog
          sx={{ zIndex: 1000000 }}
          open={!!dialogMessage}
          onClose={() => setDialogMessage("")}
        >
          <DialogContent>
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          </DialogContent>
        </Dialog>
        {currentDate < registrationOpenDate ? (
          <div
            style={{
              flex: "flex",
              padding: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <p>
              Les inscriptions pour le tournoi seront ouverte à partir du{" "}
              {`${tournamentDate.getDate()}/${tournamentDate.getMonth()}/${tournamentDate.getFullYear()}`}
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div
              style={{
                flex: "flex",
                padding: "20px",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
              }}
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    "& *": {
                      alignItems: "center",
                      gap: "1rem",
                      color: styleConst.primaryColor,
                    },
                  }}
                >
                  <Warning />
                  <p>Informations obligatoires pour chaque joueur</p>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      Date de naissance OBLIGATOIRE pour avoir une licence
                      évènementielle.
                    </li>
                    <li>
                      Code postal et lieu de résidence pour l’assurance le jour
                      du tournoi.
                    </li>
                    <li>
                      Adresse e-mail pour rester en contact avec vous
                      individuellement, et vous tenir informé.
                    </li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    "& *": {
                      alignItems: "center",
                      gap: "1rem",
                      color: styleConst.primaryColor,
                    },
                  }}
                >
                  <Warning />
                  <p>Règles pour les équipes</p>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>5 à 8 joueurs</li>
                    <li>1 fille minimum</li>
                    <li>
                      Pas plus de 2 licenciés (2024-2025) d’un club de handball.
                      Sont considérés comme licenciés : les joueurs avec une
                      licence compétition, loisir ou arbitre.
                    </li>
                    <li>8 € par personne</li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  sx={{
                    "& *": {
                      alignItems: "center",
                      gap: "1rem",
                      color: styleConst.primaryColor,
                    },
                  }}
                >
                  <Warning />
                  <p>Pour les mineurs</p>
                </AccordionSummary>
                <AccordionDetails>
                  <ul>
                    <li>
                      Les moins de 14 ans participent au tournoi enfants, les
                      14-18 ans au tournoi adultes.
                    </li>
                    <li>
                      Autorisation parentale obligatoire pour tous les joueurs
                      mineurs.
                    </li>
                    <li>8 € par personne</li>
                  </ul>
                </AccordionDetails>
              </Accordion>
              <section style={{ display: "flex", gap: "0.5rem" }}>
                <LabelInput
                  label={"Équipe"}
                  name={"name"}
                  onChange={handleChange}
                  type={"text"}
                  placeholder={"Nom de l'équipe"}
                />
                <LabelInput
                  label={"Capitaine"}
                  name={"captain"}
                  onChange={handleChange}
                  placeholder={"Nom du capitaine"}
                  type={"text"}
                />
                <LabelInput
                  label={"Téléphone"}
                  name={"telephone"}
                  onChange={handleChange}
                  placeholder={"Téléphone"}
                  type={"tel"}
                />
              </section>
            </div>
            <PlayerForm
              handleChange={handleChange}
              playersData={formData.players}
            />
            <div
              style={{
                padding: "20px",
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "center",
                gap: "1rem",
                marginBottom: 10,
              }}
            >
              {formData && formData.players.length < 8 && (
                <CustomButton
                  buttonText="Ajouter un joueur"
                  clickAction={() => addPlayer()}
                  startIcon={<PersonAdd />}
                  primaryColor={styleConst.pgbackground}
                  secondaryColor={styleConst.primaryColor}
                />
              )}
              {formData.players.length > 5 && (
                <CustomButton
                  buttonText="Supprimer un joueur"
                  clickAction={() => removePlayer()}
                  startIcon={<PersonRemoveIcon />}
                  primaryColor={styleConst.pgbackground}
                  secondaryColor={styleConst.primaryColor}
                />
              )}
            </div>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CustomButton
                buttonText="Envoyer"
                clickAction={() => removePlayer()}
                startIcon={<SendIcon />}
                disabled={!canSubmit}
                type="submit"
              />
            </Box>
            <article
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "center",
                gap: "0.5rem",
                marginTop: "1rem",
              }}
            >
              <p style={{ width: "100%", textAlign: "center" }}>
                Vous pouvez aussi télécharger votre coupon ici :
              </p>
              <CustomButton
                buttonText="Coupon adulte"
                clickAction={() => onDlBtnClick(AReg, "coupon-adulte")}
                primaryColor={styleConst.pgbackground}
                secondaryColor={styleConst.primaryColor}
              />
              <CustomButton
                buttonText="Coupon enfant"
                clickAction={() => onDlBtnClick(KReg, "coupon-enfant")}
                primaryColor={styleConst.pgbackground}
                secondaryColor={styleConst.primaryColor}
              />
              <CustomButton
                buttonText="Autorisation parentale"
                clickAction={() => onDlBtnClick(OReg, "autorisation-parentale")}
                primaryColor={styleConst.pgbackground}
                secondaryColor={styleConst.primaryColor}
              />
            </article>
          </form>
        )}
      </main>
    );
  }

  return <ContactForm />;
};

export default Registration;
