import { CheckCircle, Man, Woman } from "@mui/icons-material";
import { ChoiceToggle, DateInput, LabelInput } from "./Inputs";
import { styleConst } from "../utils/style";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";

const PlayerForm = ({ handleChange, playersData }) => {
  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: "0 20px",
      }}
    >
      {playersData &&
        playersData.map((p, index) => (
          <div
            key={index}
            style={{
              borderRadius: "4px",
              boxShadow: "1px 2px 2px gray, -1px 2px 2px gray",
              margin: 5,
              padding: 5,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <label style={{ fontSize: 15 }}>Joueur {index + 1}</label>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                gap: "0.5rem",
              }}
            >
              <LabelInput
                index={index}
                label={"Nom"}
                name={"surname"}
                onChange={handleChange}
                placeholder={""}
                type={"text"}
                maxWidth="10rem"
              />
              <LabelInput
                index={index}
                label={"Prénom"}
                name={"firstname"}
                onChange={handleChange}
                placeholder={""}
                type={"text"}
                maxWidth="10rem"
              />
              <LabelInput
                index={index}
                label={"Nom de naissance"}
                name={"birthName"}
                onChange={handleChange}
                placeholder={""}
                type={"text"}
                disabled={p.sex === "Homme"}
                maxWidth="10rem"
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "0.5rem",
              }}
            >
              <LabelInput
                index={index}
                label={"e-mail"}
                name={"email"}
                onChange={handleChange}
                placeholder={""}
                type={"text"}
              />
              <LabelInput
                index={index}
                label={"Adresse"}
                name={"adress"}
                onChange={handleChange}
                placeholder={"Code postal et lieu de résidence"}
                type={"text"}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <ChoiceToggle
                option1={"femme"}
                option2={"homme"}
                onChange={handleChange}
                index={index}
                name={"sex"}
              />
              <ChoiceToggle
                label={"Licencié.e"}
                option1={"oui"}
                option2={"non"}
                onChange={handleChange}
                index={index}
                name={"licensed"}
              />
              <DateInput
                handleChange={handleChange}
                index={index}
                label={"Date de naissance"}
                name={"birthDate"}
              />
            </div>
          </div>
        ))}
    </section>
  );
};

export default PlayerForm;
