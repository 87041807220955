import { Box, Skeleton } from "@mui/material";

export const PlanningItem = ({
  time,
  description,
  pictureSide,
  src,
  alt,
  noPicture,
}) => {
  return (
    <Box
      sx={{
        margin: "0.5rem auto",
        display: "flex",
        flexFlow: pictureSide === 0 ? "row nowrap" : "row-reverse nowrap",
        justifyContent: "space-between",
      }}
    >
      <p style={{ width: noPicture ? "100%" : "60%" }}>
        <span>{time}:</span> {description}
      </p>
      {src && alt ? (
        <img
          loading="lazy"
          alt={alt}
          src={src}
          style={{
            maxWidth: "35%",
            maxHeight: "8rem",
            objectFit: "contain",
          }}
        />
      ) : (
        !noPicture && (
          <Skeleton
            variant="rectangular"
            sx={{
              width: "35%",
              height: "8rem",
            }}
          />
        )
      )}
    </Box>
  );
};
