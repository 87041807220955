import { Button } from "@mui/material";
import { useMemo, useState } from "react";
import { styleConst } from "../../utils/style";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const CarouselButton = ({ direction, clickAction }) => {
  const [hovered, setHovered] = useState(false);
  const isForward = useMemo(() => {
    return direction === 1;
  }, [direction]);

  return (
    <Button
      sx={{
        display: "block",
        position: "absolute",
        top: "50%",
        left: isForward ? "none" : "5%",
        right: isForward ? "5%" : "none",
        color: hovered ? styleConst.pgbackground : styleConst.primaryColor,
        backgroundColor: hovered
          ? styleConst.primaryColor
          : styleConst.transparentBackground,
        border: `none`,
        borderRadius: "0",
        textDecoration: "none",
        textAlign: "center",
        padding: "0.75rem 0.5rem",
        fontFamily: "inherit",
        width: "3rem",
        height: "3rem",
        zIndex: 20,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => {
        clickAction && clickAction();
      }}
    >
      {direction === 1 ? <ArrowForwardIosIcon /> : <ArrowBackIosNewIcon />}
    </Button>
  );
};
