import { Tab, Tabs } from "@mui/material";
import { useMemo, useState } from "react";
import { YearHistory } from "../components/specific/YearHistory";
import { useNavigate } from "react-router";

const years = [
  "2003",
  "2004",
  "2005",
  "2006",
  "2007",
  "2008",
  "2009",
  "2010",
  "2011",
  "2012",
  "2013",
  "2014",
  "2015",
  "2016",
  "2017",
  "2018",
  "2022",
  "2023",
  "2024",
];

export const History = () => {
  const [currentTab, setCurrentTab] = useState(years.length - 1);
  const navigate = useNavigate();
  const renderTabs = useMemo(() => {
    const tabs = [];
    years.forEach((year) => {
      tabs.push(<Tab label={year} />);
    });
    return tabs;
  }, []);
  const handleYearChange = (event, newValue) => {
    setCurrentTab(newValue);
    navigate(`/historique/${years[newValue]}`);
  };

  return (
    <main>
      <h1>Historique du tournoi</h1>
      <Tabs
        value={currentTab}
        onChange={handleYearChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          "& *": {
            overflowX: "unset",
          },
        }}
      >
        {renderTabs}
      </Tabs>
      <YearHistory />
    </main>
  );
};
