import { forwardRef, useState } from "react";

import DatePicker from "react-datepicker";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import { fr } from "date-fns/locale";

export const CheckBoxInput = ({
  choices,
  handleChange,
  index,
  label,
  name,
  value,
}) => {
  const [selected, setSelected] = useState("");

  const handleSelect = (value) => {
    setSelected(value);
    handleChange({
      target: {
        name,
        value,
        dataset: { index },
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "30%",
      }}
    >
      <label
        style={{
          fontSize: 15,
        }}
      >
        {label}
      </label>
      <div>
        {choices.map((c) => (
          <label key={c}>
            <input
              data-index={index}
              name={name}
              type="radio"
              value={c}
              checked={selected === c}
              onChange={() => handleSelect(c)}
            />
            {c}
          </label>
        ))}
      </div>
    </div>
  );
};

export const DateInput = ({ handleChange, index, label, name }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const currentDate = new Date();

  const handleSelect = (value) => {
    setSelectedDate(value);
    handleChange({
      target: {
        name: name,
        value: value,
        dataset: { index },
      },
    });
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      style={{
        padding: "0.5rem",
        width: "100%",
        border: "1px solid #ccc",
        borderRadius: "0.375rem",
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.1)",
        backgroundColor: "white",
        color: "#4a4a4a",
        textAlign: "center",
        cursor: "pointer",
      }}
      onClick={onClick}
      ref={ref}
    >
      {value || "🗓️"}
    </div>
  ));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // width: "30%",
      }}
    >
      <label
        htmlFor="date"
        style={{
          textAlign: "center",
          fontSize: 12,
        }}
      >
        {label}
      </label>
      <DatePicker
        locale={fr}
        data-index={index}
        name={name}
        selected={selectedDate}
        onChange={handleSelect}
        dateFormat="dd/MM/yyyy"
        placeholderText="Choisir une date"
        customInput={<CustomInput />}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        yearDropdownItemNumber={80}
        maxDate={currentDate}
      />
    </div>
  );
};

export const LabelInput = ({
  index,
  label,
  name,
  onChange,
  placeholder,
  type,
  disabled,
  maxWidth,
}) => {
  return (
    <div
      style={{
        width: "50%",
        marginTop: 5,
        marginRight: 2,
        display: "flex",
        flexDirection: "column",
        maxWidth: maxWidth || "none",
      }}
    >
      <label style={{ fontSize: 14 }}>{label}</label>
      <input
        data-index={index}
        type={type}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        style={{
          padding: "6px",
          border: "1px solid grey",
          borderRadius: "4px",
          boxSizing: "border-box",
        }}
        disabled={disabled}
      />
    </div>
  );
};

export const ChoiceToggle = ({
  label,
  index,
  option1,
  option2,
  onChange,
  name,
}) => {
  const [choice, setChoice] = useState(null);

  const handleChange = (event, newChoice) => {
    if (newChoice !== null) {
      setChoice(newChoice);
      if (onChange) {
        onChange({
          target: {
            name,
            value: newChoice,
            dataset: { index },
          },
        });
      }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      {label && (
        <label
          style={{
            fontSize: 12,
          }}
        >
          {label}
        </label>
      )}
      <ToggleButtonGroup
        value={choice}
        exclusive
        onChange={handleChange}
        aria-label="choice toggle"
      >
        <ToggleButton
          value={option1}
          aria-label={option1}
          style={{ textTransform: "none" }}
        >
          {option1}
        </ToggleButton>
        <ToggleButton
          value={option2}
          aria-label={option2}
          style={{ textTransform: "none" }}
        >
          {option2}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
