import { Box, Skeleton } from "@mui/material";
import { useMemo } from "react";

export const FloatedPicture = ({
  side,
  src,
  alt,
  customHeight,
  customWidth,
}) => {
  const parsedHeight = useMemo(() => {
    return customHeight ?? "16rem";
  }, [customHeight]);
  const parsedWidth = useMemo(() => {
    return customWidth ?? "50%";
  }, [customWidth]);
  return (
    <Box
      sx={{
        float: { xs: "none", sm: side },
        margin: { xs: "0.5rem 0", sm: "0.5rem 1rem 0.5rem 0.5rem" },
        width: { xs: "100%", sm: parsedWidth },
        height: parsedHeight,
        overflow: "hidden",
      }}
    >
      {src && alt ? (
        <img
          src={src}
          alt={alt}
          style={{ objectFit: "contain", height: parsedHeight, width: "100%" }}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: parsedHeight,
          }}
        />
      )}
    </Box>
  );
};
