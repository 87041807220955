import { Box, Button, Drawer, SwipeableDrawer } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { styleConst } from "../../utils/style";
import { CustomLink } from "../generic/CustomLink";
import { Sponsors } from "../generic/Sponsors";
import { useLocation } from "react-router";

const navItems = [
  "Accueil",
  "Association",
  "Tournoi",
  "Inscription",
  "Historique",
  "Partenaires",
  // "Presse",
];

export const NavMenu = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { pathname } = useLocation();

  const isCurrentLocation = useCallback(
    (href) => {
      return href === pathname;
    },
    [pathname]
  );

  const menuContent = useMemo(() => {
    const navLinks = navItems.map((item) => {
      const href = `/${
        item === "Accueil"
          ? ""
          : item === "Historique"
          ? "historique/2024"
          : item.toLowerCase()
      }`;
      return (
        <CustomLink
          key={item}
          linkHref={href}
          linkText={item}
          primaryColor={styleConst.primaryColor}
          secondaryColor={styleConst.pgbackground}
          isActive={isCurrentLocation(href)}
          clickAction={() => setIsDrawerOpen(false)}
        />
      );
    });
    return (
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box> {navLinks}</Box>
        {window.screen.width >= 600 && <Sponsors />}
      </Box>
    );
  }, [isCurrentLocation]);

  const getPaperProps = useCallback((size) => {
    const paperProps = {
      sx: {
        width: "14rem",
        backgroundColor: styleConst.primaryColor,
        color: styleConst.pgbackground,
        padding: "0.5rem",
      },
    };
    if (size === "desktop") {
      paperProps.sx.top = "8.25rem";
      paperProps.sx.height = "calc(100% - 7.75rem)";
    } else if (size === "mobile") {
      paperProps.sx.top = "0";
      paperProps.sx.height = "100%";
    }
    return paperProps;
  }, []);

  return (
    <>
      <Box
        sx={{
          display: { xs: "flex", sm: "none" },
          flexFlow: "row nowrap",
          justifyContent: "flex-end",
          height: "3.5rem",
        }}
      >
        <Button onClick={() => setIsDrawerOpen(true)}>
          <MenuIcon
            sx={{ color: styleConst.primaryColor, fontSize: "2.5rem" }}
          />
        </Button>
      </Box>
      <SwipeableDrawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        anchor="right"
        sx={{ display: { xs: "block", sm: "none" } }}
        PaperProps={getPaperProps("mobile")}
      >
        {menuContent}
      </SwipeableDrawer>
      <Drawer
        variant="permanent"
        open
        sx={{ display: { xs: "none", sm: "block" } }}
        PaperProps={getPaperProps("desktop")}
      >
        {menuContent}
      </Drawer>
    </>
  );
};
