import { Box } from "@mui/material";
import { styleConst } from "../../utils/style";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

const SocialLink = ({ socialIcon, href }) => {
  const Icon = socialIcon;
  return (
    <a href={href} target="_blank" rel="noreferrer">
      <Icon sx={{ fontSize: "2.5rem", color: styleConst.pgbackground }} />
    </a>
  );
};

export const CustomFooter = () => {
  return (
    <Box
      sx={{
        marginTop: "2rem",
        width: { xs: "100%", sm: "calc(100% - 14rem)" },
        marginLeft: { xs: "0", sm: "14rem" },
        backgroundColor: styleConst.primaryColor,
        color: styleConst.pgbackground,
        fontSize: "0.80rem",
        height: "5rem",
        display: "flex",
        flexFlow: "row nowrap",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "fit-content", sm: "100%" },
          margin: { xs: "0", sm: "0 2rem" },
          gap: "0.5rem",
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <p>Association "Pour Guillaume",</p>
          <p>Le Domaine,</p>
          <p>05500 Saint-Bonnet</p>
        </Box>
        <Box>
          <SocialLink
            socialIcon={InstagramIcon}
            href={
              "https://www.instagram.com/associationpourguillaume?igsh=MXByZWM5YmY1NnFleA=="
            }
          />
          <SocialLink
            socialIcon={FacebookIcon}
            href={"https://www.facebook.com/366364753427007?ref=embed_page"}
          />
        </Box>
        <Box>
          <p>Contacter l'asso:</p>
          <a
            href="mailto:assopourguillaume@gmail.com"
            style={{ color: styleConst.pgbackground }}
          >
            assopourguillaume@gmail.com
          </a>
          <a
            href="tel:+33670636502"
            style={{ display: "block", color: styleConst.pgbackground }}
          >
            0670636502
          </a>
        </Box>
      </Box>
    </Box>
  );
};
