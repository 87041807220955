import { Box, Skeleton } from "@mui/material";
import { styleConst } from "../../utils/style";

export const AssoMember = ({
  src,
  alt,
  memberRole,
  objectFit,
  objectPosition,
}) => {
  const parsedName = memberRole ? alt + ", " : alt;

  return (
    <Box
      sx={{
        // width: "100%",
        // maxWidth: "20rem",
        width: "10rem",
        height: "14rem",
        display: "flex",
        flexFlow: "column nowrap",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: "1rem",
        borderWidth: "1px",
        borderColor: styleConst.primaryColor,
        borderStyle: "solid",
        borderRadius: "4px",
        overflow: "hidden",
      }}
    >
      {src && alt ? (
        <img
          loading="lazy"
          alt={alt}
          src={src}
          style={{
            width: "10rem",
            height: "10rem",
            objectFit: objectFit || "cover",
            objectPosition,
          }}
        />
      ) : (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "10rem",
            height: "10rem",
          }}
        />
      )}
      <p style={{ textAlign: "center" }}>
        {parsedName}
        <span>{memberRole}</span>
      </p>
    </Box>
  );
};
