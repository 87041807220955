export const PressItem = ({ date, paper, href }) => {
  return (
    <a
      href={href}
      style={{ textDecoration: "none", display: "block" }}
      target="_blank"
      rel="noreferrer"
    >
      {paper} du {date}
    </a>
  );
};
